import { Link } from "gatsby"
import React from "react"

const Footer = () => (
  <footer className="border-t border-gray-400">
    <div className="items-center mx-auto divide-y divide-gray-400 max-w-screen-2xl md:grid md:grid-cols-2 md:divide-y-0">
      <div>
        <div className="max-w-xl p-6 mx-auto md:py-12 lg:py-24 lg:text-lg">
          <h3 className="text-xl h1 lg:mb-8">Kontakt</h3>
          <div className="flex flex-wrap gap-6 md:gap-12 md:flex-row md:flex-no-wrap">
            <div>
              <address className="not-italic">
                <div className="mb-2">
                  E-mail: <a className="link" href="mailto:sales@rentware.io">sales@rentware.io</a><br/>
                  Tel.: <a href="tel:+4930124245645">+49 (0) 30 124 245 645</a>
                </div>
                <div>
                  Ernst-Augustin-Str. 12<br/>
                  12489 Berlin<br/>
                  Germany
                </div>
              </address>
            </div>
            <div>
              <nav>
                <ul>
                  <li>
                    <Link to="/de/impressum">Impressum</Link>
                  </li>
                  <li>
                    <Link to="/de/datenschutz">Datenschutz</Link>
                  </li>
                  {/* <li>
                    <a href="">Cookie settings</a>
                  </li> */}
                  {/* We don't have terms and conditions (2020-12-29)
                  <li>
                    <Link to="/terms-and-conditions">Terms and conditions</Link>
                  </li> */}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
