import React from "react";
import PropTypes from "prop-types";

import Footer from "./footer.de"
import TheEnd from "./theEnd";
export default function LayoutDe({ children }) {
  return (
    <>
      <>{children}</>
      <Footer />
      <TheEnd />
    </>
  )
}

LayoutDe.propTypes = {
  children: PropTypes.node.isRequired,
};