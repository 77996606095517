import React from "react"
import { Link } from "gatsby"

import LogoText from "../assets/svg/rentware-logo-text.svg"
import LogoIcon from "../assets/svg/rentware-logo-icon.svg"

const NavDe = (props) => (
    <nav className="bg-white">
        <ul className="flex flex-wrap items-center mx-auto text-sm max-w-screen-3xl md:h-28 md:justify-start lg:py-2">
            <li className="w-auto p-4">
                <Link to="/de/">
                    <LogoIcon className="w-16 h-10 md:inline-block md:" />
                    <LogoText className="w-16 h-auto mt-2 md:inline-block md:w-28" />
                </Link>
            </li>
            <li className="ml-auto p-4">
                <Link to="/"
                className="px-2 py-4 mb-0 btn btn--tertiary"
                >
                    english
                </Link>
            </li>
        </ul>
    </nav>
)
    
export default NavDe